import { get, post, put, Delete } from "../axios";

// Q&A列表-列表
export const qaManageList = (params) => get("/web/qa/manage/list", params);

// Q&A列表-新增
export const qaManageAdd = (params) => post("/web/qa/manage/add", params);

// Q&A列表-编辑
export const qaManageUpdate = (params) => put("/web/qa/manage/update", params);

// Q&A列表-删除
export const qayManageDelete = (id) =>
  Delete("/web/qa/manage/delete/" + id, "");

// 权益列表-详情
export const qaManageView = (id) => get("/web/qa/manage/view/" + id, "");

// Q&A列表-首页推荐-状态编辑
export const qaManageHomeUpdate = (params) =>
  put("/web/qa/manage/home/update", params);
