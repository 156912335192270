<template>
  <div>
    <el-dialog
      v-if="show"
      title=""
      :visible.sync="show"
      width="50%"
      :before-close="handleClose"
      v-loading="dialogLoading"
      :close-on-click-modal="false"
    >
      <avue-crud
        ref="crud"
        :data="tableData"
        :option="option"
        :table-loading="showLoading"
        :page.sync="page"
        @on-load="onLoad"
      >
        <template #header>
          <div>
            <div class="flex flex-wrap align-items marginBottom20">
              <div class="flex align-items marginRight10 marginBottom10">
                <span class="span marginRight10">账号姓名 : </span>
                <div class="width300">
                  <el-input
                    v-model="searchData.deviceId"
                    placeholder="请输入"
                    clearable
                    maxlength="50"
                  ></el-input>
                </div>
              </div>
              <div class="flex align-items marginRight10 marginBottom10">
                <span class="span marginRight10">手机号码 : </span>
                <div class="width300">
                  <el-input
                    v-model="searchData.phone"
                    placeholder="请输入"
                    clearable
                    maxlength="50"
                  ></el-input>
                </div>
              </div>
              <div class="flex align-items marginRight10 marginBottom10">
                <span class="span marginRight10">创建时间 : </span>
                <div class="width300">
                  <el-date-picker
                    v-model="searchData.createTime"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="起始时间"
                    end-placeholder="结束时间"
                    :clearable="true"
                  >
                    <!-- @change="handleSearch" -->
                  </el-date-picker>
                </div>
              </div>
              <el-button
                size="medium"
                type="primary"
                @click="handleSearch"
                style="margin-bottom: 10px"
                class="marginLeft10"
                >搜索</el-button
              >
              <el-button
                size="medium"
                type="info"
                @click="handleReset"
                class="marginLeft10"
                style="margin-bottom: 10px"
                >重置</el-button
              >
            </div>
          </div>
        </template>
        <!-- <template slot="softwareVersion" slot-scope="{ row }">
          <div>
            {{ row.softwareVersion }}
          </div>
        </template> -->
      </avue-crud>
      <div style="text-align: center; margin-top: 30px">
        <el-button class="marginLeft10" type="" @click="handleClose"
          >关闭</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "index",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchData: {},
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      showLoadingForm: false,
      dialogVisible: false,
      option: {
        rowKey: "id",
        title: "",
        titleSize: "h3",
        card: false,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        page: true, // 显示分页
        align: "center",
        menu: false,
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        tip: false,
        column: [
          {
            label: "账号姓名",
            prop: "deviceId",
          },
          {
            label: "岗位",
            prop: "name",
          },
          {
            label: "手机号码",
            prop: "phone",
          },
          {
            label: "创建时间",
            prop: "bindTime",
          },
        ],
      },
      dialogLoading: false,
    };
  },
  // watch: {
  //   show(value) {
  //     this.onLoad();
  //   },
  // },
  created() {},
  methods: {
    onLoad() {
      this.showLoading = true;
      const searchData = {
        ...this.searchData,
      };
      if (this.searchData.createTime) {
        searchData.bindTimeStart = dayjs(this.searchData.createTime[0]).format(
          "YYYY-MM-DD 00:00:00"
        );
        searchData.bindTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      delete searchData.createTime;
      // upgradeDeviceList({
      //   page: this.page.currentPage,
      //   size: this.page.pageSize,
      //   ...searchData,
      // }).then((res) => {
      this.showLoading = false;
      //   if (res.code === 200) {
      //     this.tableData = res.data.records;
      //     this.page.total = res.data.total;
      //   }
      // });
    },

    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      // console.log('重置')
      this.searchData = {};
      this.page.currentPage = 1;
      this.onLoad();
    },

    //输入
    salaryDianChange(e, t) {
      const value = e.target.value;
      this.form[t] = value;
    },
    //关闭
    handleClose() {
      this.$emit("closeTable");
    },
  },
};
</script>

<style scoped>
.title {
  text-align: center;
}
</style>
