<template>
  <div class="main-content" id="content">
    <div class="title">会员详情</div>
    <div style="align-items: center; width: 100%; margin-bottom: 30px">
      <div class="flex">
        <div class="tit_left">
          <ElAvatar
            shape="square"
            :size="120"
            :src="user.logo"
            @click="handleView(user.logo)"
          />
        </div>
        <!-- <div class="tit_left">
          <ElAvatar
            shape="square"
            :size="120"
            :src="user.businessLicense"
            @click="handleView(user.businessLicense)"
          />
        </div> -->
      </div>
      <div class="basics_box">
        <div class="tit_right">
          <!-- <div class="right_box">
            <span class="right_label"> 商家编号 : </span> {{ user.id }}
          </div>
          <div class="right_box">
            <span class="right_label"> 商家名称 : </span> {{ user.name }}
          </div> -->
        </div>
        <div class="tit_right">
          <!-- <div class="right_box">
            <span class="right_label"> 授权证书 </span>
          </div>
          <div class="right_box">{{ user.businessLicenseNo }}</div> -->
        </div>
      </div>
    </div>

    <el-descriptions title="" :column="4">
      <el-descriptions-item label="会员编号">kooriookami</el-descriptions-item>

      <el-descriptions-item label="会员姓名">苏州市</el-descriptions-item>
      <el-descriptions-item label="所属公司"> 公司 </el-descriptions-item>
      <el-descriptions-item label="分类">分类</el-descriptions-item>
      <el-descriptions-item label="职位">职位</el-descriptions-item>
      <el-descriptions-item label="手机号码">分类</el-descriptions-item>
      <el-descriptions-item label="密码"
        ><span style="color: #409eff; cursor: pointer" @click="getType('密码')">
          重置密码
        </span></el-descriptions-item
      >
      <el-descriptions-item label="权益">权益</el-descriptions-item>
      <el-descriptions-item label="状态">状态</el-descriptions-item>
      <el-descriptions-item label="添加时间">2024</el-descriptions-item>
      <el-descriptions-item label="到期时间">2024</el-descriptions-item>
      <el-descriptions-item label="子账号数"
        ><span style="color: #409eff; cursor: pointer" @click="getType('账号')">
          100
        </span></el-descriptions-item
      >
      <el-descriptions-item label="授权总数">授权总数</el-descriptions-item>
      <el-descriptions-item label="已使用数">分类</el-descriptions-item>
      <el-descriptions-item label="未使用数">分类</el-descriptions-item>
      <el-descriptions-item label="所拥有徽章">分类</el-descriptions-item>

      <el-descriptions-item label="会员介绍">分类</el-descriptions-item>
    </el-descriptions>
    <Table :show="tableShow" @closeTable="closeTable" />
    <el-image-viewer
      v-if="imgViewerVisible"
      :on-close="closeImgViewer"
      :url-list="urlViewer"
    />
  </div>
</template>

<script>
import { qaManageAdd, qaManageUpdate, qaManageView } from "@/api/qa/manage";
import dayjs from "dayjs";
import Table from "./table";
import { Message } from "element-ui";

export default {
  name: "create",
  components: {
    Table,
    "el-image-viewer": () =>
      import("element-ui/packages/image/src/image-viewer"),
  },
  data() {
    return {
      classifyList: [],
      id: this.$route.query.id,
      disabled: this.$route.query.type === "view",
      type: this.$route.query.type,
      user: {
        content: "",
        title: "",
      },
      loadingSubmit: false,
      tableShow: false, //表格是否显示
      imgViewerVisible: false, // 图片预览
      urlViewer: [], // 图片预览
    };
  },
  mounted() {
    // this.getView();
  },
  methods: {
    // 详情获取
    async getView() {
      if (this.id) {
        const { data } = await qaManageView(this.id);
        this.form = {
          ...data,
        };
      }
    },
    // 跳转或者打开弹窗
    getType(type) {
      switch (type) {
        case "密码":
          this.handleResetPwd(this.id);
          break;
        case "账号":
          this.tableShow = true;
          break;
        default:
          break;
      }
    },
    /** 重置密码 */
    async handleResetPwd(id) {
      try {
        // 重置的二次确认

        const result = await this.$prompt(
          "请输入" + "xx会员" + "的新密码",
          "温馨提示",
          {
            type: "warning",
            inputPattern: /^.{6,20}$/,
            inputErrorMessage: "密码长度 6-20位",
          }
        );

        const password = result.value;
        // 发起重置
        // await TenantApi.tenantUpdatePassword(id, password);
        Message.success("修改成功，新密码是：" + password);
        // this.getView()
      } catch {}
    },
    // 关闭表格
    closeTable() {
      this.tableShow = false;
    },
    //预览
    handleView(url) {
      this.imgViewerVisible = true;
      this.urlViewer = [url];
      const m = (e) => {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", m, false); // 禁止页面滑动
    },
  },
};
</script>

<style scoped>
.main-content {
  padding-left: 30px;
  /* padding-right: 40%; */
}
.tit_left {
  width: 15%;
}
.basics_box {
  display: flex;
}
.tit_right {
  width: 15%;
}
.right_box {
  margin-bottom: 12px;
  display: block;
  align-items: center;
  font-size: 14px;
  color: #606266;
}
.right_label {
  display: inline-block;
  width: 80px;
  text-align: left;
  font-size: 14px;
  margin-right: 16px;
  color: #303133;
}
.title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}
</style>
